<template>
  <div class="p-fluid">
    <div class="p-col-12 p-lg-12">
      <div class="card card-w-title">
        <div class="manager-title">{{ title }}</div>
        <!--<div class="note">(*) Nội dung không được bỏ trống</div>-->
        <formEdit
          :key="componentKey"
          :mode="mode"
          @back="backToList"
          @save="saveData"
          :model="models"
        />

      </div>
    </div>
  </div>
</template>

<script>
// import gql from "graphql-tag";
// import Config from '../../../config/index'
import DataServices from '@/core/DataServices'
import formEdit from './add'
// import {DATE} from "sequelize";
//import firebase from "../../../../firebase.json";
export default {
  components: {
    formEdit
  },
  data() {
    return {
      validationErrors: [],
      title: "Tạo Kho Hàng ",
      mode: 'add',
      models: {},
      models_company: {},
      images: [],
      oldImages: [],
      componentKey: 0,
      page_transaction:null
    }
  },
  async mounted () {
    console.log("this.$route.name:",this.$route.params.id);
    this.mode = this.$route.name === 'editWarehouse' ? 'edit' : this.mode;
    this.title = this.mode === 'edit' ? 'Chỉnh sửa Kho Hàng' : this.title;
    if (this.$route.params.id !== undefined) await this.getData(this.$route.params.id);
    if (!this.$commonFuction.isEmpty(this.$route.query)){
      if (!this.$commonFuction.isEmpty(this.$route.query.page_transaction)){
        this.page_transaction = this.$route.query.page_transaction;
      }
    }
    //check quyền
    var arr_role = await this.$CoreService.checkPermission(this.$constants.KEY_MENU.LIST_WAREHOUSE);
    if (this.mode == 'edit'){
      if (arr_role.indexOf(this.$constants.ROLE.EDIT) < 0){
        this.$router.push({path: this.$constants.URL_ERR.FOBIDDEN});
      }
    }else {
      if (arr_role.indexOf(this.$constants.ROLE.ADD) < 0){
        this.$router.push({path: this.$constants.URL_ERR.FOBIDDEN});
      }
    }
  },
  methods: {
    backToList() {
      this.$router.push({ path: '/admin/list-warehouse' });
    },
    async saveData(params) {
      console.log("1234");
      console.log(params);
      console.log("1234");
      var street = params.address+', '+ params.ward_name +', '+ params.district_name +', '+ params.state_name+', '+ params.country_name
      //var street = params.address +','+ params.country_name+','+ params.state_name+','+ params.district_name+','+ params.ward_name
      if (params !== undefined && Object.entries(params).length > 0) {
        var uid = this.$store.getters.user;
        var updated_at = new Date();
        var objects = {}
          objects = {
            'name': params.name,
            'code': params.code,
            'address': street,
            'street': params.address,
            'country_id':params.country_id,
            'state_id':params.state_id,
            'district_id':params.district_id,
            'type_id':params.type_id,
            'ward_id':params.ward_id,
            'active': params.active,
            'updated_by': uid,
            'updated_at': updated_at,
            'protect': params.protect,
          }
        console.log("object",objects)

        var that = this;
        if (this.mode === 'add') {
          var dataInsert = this.$commonFuction.convertJsonObject(objects);

          await that.$apollo
              .mutate({
                mutation: DataServices.insertData('warehouse'),
                variables: {
                  objects: dataInsert
                }
              })
              .then( async (res)  => {
                if (res.data[Object.keys(res.data)].affected_rows)
                {
                   var returning = res.data[Object.keys(res.data)].returning[0];
                  console.log("return",returning.id)
                }
                console.log('Thành công');
                console.log("idididi",res);//
                this.$commonFuction.is_loading(false);
                that.$toast.add({severity: 'success', summary: 'Thông báo', detail: 'Thêm thành công', life: 3000});
                this.$router.push({path: '/admin/list-warehouse'});
              }).catch(async error => {
                console.log('error', error);
                that.$toast.add({
                  severity: 'error',
                  summary: that.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
                  detail: that.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
                  life: 3000
                });

                this.$commonFuction.is_loading(false);
                // this.summitEvent = false;
              });
        }
// edit
        if (this.mode === 'edit') {
          var id = this.models.id;
          console.log("idididi");
          console.log(id);
          var dataUpdate = this.$commonFuction.convertJsonObject(objects);

          console.log("dataupdate" , dataUpdate)
          console.log("ididid" , id)
          let variables = {
            'data_update': dataUpdate,
            'where_key': {
              'id': {'_eq': id}
            }
          }
          await this.$apollo.mutate({
            mutation: DataServices.updateData('warehouse'),
            variables: variables
          })
         .then( async (res)  => {
                console.log('Thành công');
                console.log(res);//
                this.$commonFuction.is_loading(false);
                that.$toast.add({severity: 'success', summary: 'Thông báo', detail: 'Cập nhật thành công', life: 3000});
                this.$router.push({path: '/admin/list-warehouse'});
              }).catch(async error => {
                console.log('error', error);
                that.$toast.add({
                  severity: 'error',
                  summary: that.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
                  detail: that.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
                  life: 3000
                });
                this.$commonFuction.is_loading(false);
                // this.summitEvent = false;
              });
        }
      }
    },
    async getData(id) {
      var {data} = await this.$apollo.query({
        query: DataServices.getList('warehouse'),
        variables: {
          where_key: {
            id: {'_eq': id},
          }
        },
        fetchPolicy: "network-only"
      })
      console.log("datadata:",data);
      var model = data[Object.keys(data)];
      if (model.length > 0) {
        this.models = model[0];
      }
      this.componentKey++;
    },
  },
  apollo: {
  }
}
</script>

<style lang="scss">
.p-inputnumber-buttons-stacked {
  .p-inputnumber-button-group {
    .p-button.p-button-icon-only { padding: 0 !important; }
  }
}
.note{ letter-spacing: 0px; color: #707070; margin-bottom: 24px; padding-top: 10px;}
.p-formgrid { margin: 0;
  .p-panel-header {color: #000000; font-size: 18px; font-weight: bold; background: #EEEEEE;}
  .p-card-field{ padding: 24px;
    .p-field{
      .p-col-fixed { width: 300px; display: inline-block !important; margin: 0;}
      .p-col-width{width:500px !important; border-radius: 5px;
        &.disabled{background: #EEEEEE; border: 0 none; padding: 8px 10px;}
        .file-select{margin: 0;
          input[type="file"]{display: none;}
          .select-button{display: inline-block; cursor: pointer; background: transparent; border-radius: 5px;  color: #000000; font-size: 13px; padding: 3px 16px;  border: 1px solid #B4B4B4;}
        }
        &.pac-target-input{ height: 80px;  word-break: break-all; overflow-wrap: break-word;}
      }
      .box-images{ margin-top: 10px; position: relative;
        .item-img{height: 150px;  margin-right: 8px; margin-bottom: 8px; display: inline-flex; position: relative; border: 0.5px solid #ccc;
          &.p-col-5{width: 18%;;}
          img{max-width: 100%; height: auto;margin: 0 auto; object-fit: contain;}
          .button-clear{width: 18px; height: 18px; background: #F5564E; float: right; cursor: pointer; position: absolute; right: 0px;
            img{vertical-align: top;}
          }
        }
      }
      .p-invalid{display: block;}
    }
  }
  .fixed-bottom-toolbar{background: transparent; border: 0 none; margin-top: 22px; border-top: 1px solid #B4B4B4; padding: 0; padding-top: 30px;
    .p-button{width: auto; margin-right: 8px;
      &:last-child{margin-right: 0;}
    }
  }
}
</style>
<style>
.list-group-v {
  /* display: -webkit-box;
   display: -ms-flexbox;*/
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  /*-ms-flex-direction: column;*/
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}
.list-group-item-v:first-child {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}
.list-group-item-v {
  position: relative;
  display: block;
  padding: .75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0,0,0,.125) ;
  height: 50px;
}
.box-check-box{
  position: relative;
}
.box-check-box label{
  position: absolute;
  left: 0px;
  top: 2px;
}
.box-check-box .check-box-day{
  position: absolute;
  right: 0px;
  top: 2px;
}

</style>
<template class="back_ground">
  <form id="frm_action">
    <div class="p-formgrid">
      <label style="font-size: 18px" class="p-col-fixed txt-right margin"> THÔNG TIN KHO </label>
      <div>
        <div class="p-card-field">
          <label class="p-col-fixed txt-right ">Tên kho<span style="color: red"> *</span></label>
          <div class="p-col">
            <Textarea :autoResize="true"  rows="1" maxlength="255" required v-model="warehouses.name"  id="name" type="text" class="p-col-width text-area input_text_responsive border_color" placeholder="Nhập " />
          </div>
        </div>
        <div class="p-card-field">
          <label class="p-col-fixed txt-right ">Mã kho<span style="color: red"> *</span></label>
          <div class="p-col">
            <InputText maxlength="50" required v-model="warehouses.code"  id="code" type="text" class="p-col-width input_text input_text_responsive border_color" placeholder="Nhập " />
          </div>
        </div>
        <div class="p-card-field">
          <label class="p-col-fixed txt-right"> Phân Loại <span style="color: red"> *</span></label>
          <div class="p-col">
            <Dropdown id="type" title="Loai" class="input_width input_text_responsive border_color" v-model="selectedType" :options="type" optionLabel="name" optionValue="id" placeholder="Chọn" />
              <span slot="no-options" @click="$refs.select.open = false">
              </span>
            <input class="input_tmp_validator" id="vali_type">
          </div>
        </div>
        <div class="p-card-field">
          <div class="p-col">
            <Checkbox class="check_online"   v-model="warehouses.protect" :binary="true"/>
            <label class="p-col-fixed txt-right text">Kho có bảo vệ</label>
          </div>
        </div>

        <label style="font-size: 18px" class="p-col-fixed txt-right margin">ĐỊA CHỈ KHO</label>
        <div class="p-card-field">
            <label class="p-col-fixed txt-right">Quốc gia <span style="color: red">*</span></label>
          <div class="p-col" >
            <v-select :clearable="false" title="Quoc gia" @search="SearchCountry" v-on:input="change_country"   id= "country" class="input_width input_text_responsive" :filterable="false" placeholder="Chọn" v-model="selectedCountry" :options="country"  label="name"  >
              <span slot="no-options"  @click="$refs.select.open = false">
                Không có dữ liệu
              </span>
            </v-select>
            <input  class="input_tmp_validator" id="vali_country">
          </div>
          </div>
        <div class="p-card-field" >
          <label class="p-col-fixed txt-right">Tỉnh/ Thành Phố <span style="color: red">*</span></label>
          <div class="p-col" >
            <v-select :clearable="false" title="Tinh" id= "state" @search="SearchState" v-on:input="change_state" class="input_width input_text_responsive" :filterable="false"  placeholder="Chọn" v-model="selectedState" :options="state" label="name"   >
              <span  slot="no-options" @click="$refs.select.open = false" >
                Không có dữ liệu
              </span>
            </v-select>
            <input class="input_tmp_validator" id="vali_state">
          </div>

        </div>
        <div class="p-card-field">
          <label class="p-col-fixed txt-right">Quận/ Huyện <span style="color: red">*</span></label>
          <div class="p-col">
            <v-select :clearable="false" title="Tinh" id= "district" @search="SearchDistrict" v-on:input="change_district" class="input_width input_text_responsive" :filterable="false" placeholder="Chọn" v-model="selectedDistrict" :options="district" label="name"  >
              <span slot="no-options" @click="$refs.select.open = false">
                Không có dữ liệu
              </span>
            </v-select>
            <input class="input_tmp_validator" id="vali_district">
          </div>


        </div>
        <div class="p-card-field">
          <label class="p-col-fixed txt-right">Phường/ Xã <span style="color: red">*</span></label>
          <div class="p-col">
            <v-select :clearable="false" title="Phuong/Xa" @search="SearchWard" id= "ward" class="input_width input_text_responsive" :filterable="false" placeholder="Chọn" v-model="selectedWard" :options="ward" label="name"  >
              <span slot="no-options" @click="$refs.select.open = false">
                Không có dữ liệu
              </span>
            </v-select>
            <input class="input_tmp_validator" id="vali_ward">
          </div>
        </div>
        <div class="p-card-field">
            <label class="p-col-fixed txt-right ">Số nhà, tên đường <span style="color: red">*</span> </label>
            <div class="p-col">
              <Textarea :autoResize="true"  rows="1" maxlength="255" required v-model="warehouses.address"  id="address" type="text" class="p-col-width text-area input_text_responsive border_color" placeholder="Nhập " />
            </div>
          </div>
        <div class="p-card-field">
            <div class="p-col">
              <Checkbox class="check_online"  v-model="warehouses.active"  :binary="true"/>
              <label class="p-col-fixed txt-right text">Hoạt động</label>
            </div>
          </div>
      </div>
      <Toolbar class="fixed-bottom-toolbar div-button">
        <template slot="right">
          <Button
              :label="$constants.TEXT_BUTTON.BACK"
              @click="backToList()"
              class="p-button-warning back"
              id="back"
          />
          <Button
              v-if="mode == 'edit'"
              :label="$constants.TEXT_BUTTON.EDIT"
              @click="saveData()"
              class="main-style-button"
          />
          <Button
              v-else
              :label="$constants.TEXT_BUTTON.ADD"
              @click="saveData()"
              class="main-style-button"
          />
        </template>
      </Toolbar>
    </div>
  </form>
</template>
<script>
import DataServices from '@/core/DataServices'
import warehouse from "./index.vue";
import dataServices from "@/core/DataServices";
// import gql from "graphql-tag";

export default {
  computed: {
    warehouse() {
      return warehouse
    },
  },
  props: {
    mode: String,
    model: Object,
    users: Array
  },
  data() {
    return {

      warehouses:{
        name:  null,
        code: null,
        address: null,
        protect: true,
        active: true,
      },
      selectedCountry: null,
      selectedState: null,
      selectedDistrict: null,
      selectedType: null,
      selectedWard: null,
      country: [],
      state: [],
      district: [],
      type: [],
      ward: [],
      list_district_all: [],
      list_state_all: [],
      list_country_all: [],
      list_ward_all: [],
      code: '',
      validationErrors: {},
      formMode: this.mode,
      images: [],
      imagesDeletes: [],
      maxFileSize: 1000000, // 1MB
      maxFiles: 10,
      account_exist: false,
      firebaseID: null,
      userID: null,
      list_group_role:[],
      saving:false,
      addressData:{"street_number":"251","route":"Quang Trung","administrative_area_level_1":"Thành phố Hồ Chí Minh","country":"Việt Nam","latitude":10.8286588,"longitude":106.6732573},
      placeResultData:{"address_components":[{"long_name":"251","short_name":"251","types":["street_number"]},{"long_name":"Quang Trung","short_name":"Quang Trung","types":["route"]},{"long_name":"Gò Vấp","short_name":"Gò Vấp","types":["administrative_area_level_2","political"]},{"long_name":"Thành phố Hồ Chí Minh","short_name":"Thành phố Hồ Chí Minh","types":["administrative_area_level_1","political"]},{"long_name":"Việt Nam","short_name":"VN","types":["country","political"]}],"adr_address":"<span class=\"street-address\">251 Quang Trung</span>, <span class=\"extended-address\">Phường 10</span>, <span class=\"locality\">Gò Vấp</span>, <span class=\"region\">Thành phố Hồ Chí Minh</span>, <span class=\"country-name\">Việt Nam</span>","formatted_address":"251 Quang Trung, Phường 10, Gò Vấp, Thành phố Hồ Chí Minh, Việt Nam","geometry":{"location":{"lat":10.8286588,"lng":106.6732573},"viewport":{"south":10.8273459697085,"west":106.6719226697085,"north":10.8300439302915,"east":106.6746206302915}},"icon":"https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/geocode-71.png","name":"251 Quang Trung","place_id":"ChIJ8-A91QMpdTERWj0x_fnFL2Y","plus_code":{"compound_code":"RMHF+F8 Gò Vấp, Thành phố Hồ Chí Minh, Việt Nam","global_code":"7P28RMHF+F8"},"reference":"ChIJ8-A91QMpdTERWj0x_fnFL2Y","types":["street_address"],"url":"https://maps.google.com/?q=251+Quang+Trung,+Ph%C6%B0%E1%BB%9Dng+10,+G%C3%B2+V%E1%BA%A5p,+Th%C3%A0nh+ph%E1%BB%91+H%E1%BB%93+Ch%C3%AD+Minh,+Vi%E1%BB%87t+Nam&ftid=0x31752903d53de0f3:0x662fc5f9fd313d5a","utc_offset":420,"html_attributions":[],"utc_offset_minutes":420}
    }
  },
  async mounted() {


    // đổ dữ liệu khi edit
    if (this.model !== undefined && Object.keys(this.model).length > 0) {
// lấy data đổ vào input
      this.warehouses = this.$commonFuction.convertJsonObject(this.model);
      this.warehouses.address = this.warehouses.street
      this.warehouses.active  = this.model.active
      this.warehouses.protect  = this.model.protect
      console.log("groupch  annel123", this.warehouses.active)

    }

    // đổ danh sach  data ra khi add hoac edit select data
    var list_country = DataServices.getList('country');
    var where = {
      'where_key': {
        'deleted': {'_eq': false},
      }
    }
    var resdata_country = await this.$apollo.mutate({
      mutation: list_country,
      variables: where
    });
    var country = resdata_country.data['country'];
    this.country = country;
    this.selectedCountry = country[0]

    console.log("country",this.country)




    // đổ danh sach  data ra khi add hoac edit select data
    var list_state = DataServices.getList('state');
    var where_state = {country_id: {_eq: this.selectedCountry.id},deleted: {_eq: false}};
    console.log("dimotngay",this.state)
    var resdata_state = await this.$apollo.mutate({
      mutation: list_state,
      variables: {
        where_key:where_state,
        orderBy: {created_at: 'desc'}
      }
    });
    var state = resdata_state.data['state'];
    this.state = state;
    console.log("dimotngay",this.state)




    // đổ danh sach  data ra khi add hoac edit select data
    // var list_district = DataServices.getList('district');
    // var resdata_district = await this.$apollo.mutate({
    //   mutation: list_district,
    //   variables: {
    //     orderBy: {created_at: 'desc'}
    //   }
    // });
    // var district = resdata_district.data['district'];
    // this.district = district;




    // đổ danh sach  data ra khi add hoac edit select data
    var list_type = DataServices.getList('type_warehouse');
    var resdata_type = await this.$apollo.mutate({
      mutation: list_type,
      variables: {
        orderBy: {created_at: 'desc'}
      }
    });
    var type = resdata_type.data['type_warehouse'];
    this.type = type;





    // đổ danh sach  data ra khi add hoac edit select data
    // var list_ward = DataServices.getList('ward');
    // var resdata_ward = await this.$apollo.mutate({
    //   mutation: list_ward,
    //   variables: {
    //     orderBy: {created_at: 'desc'}
    //   }
    // });
    // var ward = resdata_ward.data['ward'];
    // this.ward = ward;



    // đổ data đã chọn  ra khi edit
    console.log(this.mode,"modeeeeee")
    if (this.mode == 'edit') {
      var selected_country = (this.warehouses.country) ? this.warehouses.country:[];
        this.selectedCountry = selected_country;



      var selected_state = (this.warehouses.state) ? this.warehouses.state:[];
      this.selectedState = selected_state;

      var selected_district = (this.warehouses.district) ? this.warehouses.district:[];
      this.selectedDistrict = selected_district;

      var selected_type = (this.warehouses.type_warehouse) ? this.warehouses.type_warehouse:[];
      this.selectedType = selected_type.id;

      var selected_ward = (this.warehouses.ward) ? this.warehouses.ward:[];
      this.selectedWard = selected_ward;
      console.log(this.selectedCountry,"modeeeeee212434")


      // đổ dữ liệu theo cái id của country
      console.log("wqwqsasasa",this.selectedCountry)
      var country_id  =  this.selectedCountry.id
      console.log("ryeyrere",country_id)
      let variables_add_edit_state = {
        'where_key': {
          'country_id': {'_eq': country_id}
        }
      }
      var list_state_add_edit = DataServices.getList('state');
      var resdata_state_add_edit = await this.$apollo.mutate({
        mutation: list_state_add_edit,
        variables: variables_add_edit_state
      });
      var state_add_edit = resdata_state_add_edit.data['state'];
      this.state = state_add_edit;


      // đổ dữ liệu theo cái id của state
      console.log("wqwqsasasa",this.selectedState)
      var state_id  =  this.selectedState.id
      console.log("ryeyrere",state_id)
      let variables_add_edit_district = {
        'where_key': {
          'state_id': {'_eq': state_id}
        }
      }
      var list_district_add_edit = DataServices.getList('district');
      var resdata_district_add_edit = await this.$apollo.mutate({
        mutation: list_district_add_edit,
        variables: variables_add_edit_district
      });
      var district_add_edit = resdata_district_add_edit.data['district'];
      this.district = district_add_edit;



      // đổ dữ liệu theo cái id của district
      console.log("wqwqsasasa",this.selectedWard)
      var district_id  =  this.selectedWard.id
      console.log("ryeyrere",district_id)
      let variables_add_edit_ward = {
        'where_key': {
          'district_id': {'_eq': district_id}
        }
      }
      var list_ward_add_edit = DataServices.getList('ward');
      var resdata_ward_add_edit = await this.$apollo.mutate({
        mutation: list_ward_add_edit,
        variables: variables_add_edit_ward
      });
      var ward_add_edit = resdata_ward_add_edit.data['ward'];
      this.ward = ward_add_edit;


    }

  },
  methods: {
     async change_country() {
       this.selectedState = ''
        var id  =  this.selectedCountry.id
       let variables = {
         'where_key': {
           'country_id': {'_eq': id}
         }
       }
       var list_state = DataServices.getList('state');
       var resdata_state = await this.$apollo.mutate({
         mutation: list_state,
         variables: variables
       });
       var state = resdata_state.data['state'];
       this.state = state;

    },
    async change_state() {
       this.selectedDistrict = ''
      var id  =  this.selectedState.id
      console.log("chinghetieng",id)
      let variables = {
        'where_key': {
          'state_id': {'_eq': id}
        }
      }
      var list_district = DataServices.getList('district');
      var resdata_district = await this.$apollo.mutate({
        mutation: list_district,
        variables: variables
      });
      var district = resdata_district.data['district'];
      this.district = district;

    },
    async change_district() {
       this.selectedWard = ''
      var id  =  this.selectedDistrict.id
      console.log("chinghetieng",id)
      let variables = {
        'where_key': {
          'district_id': {'_eq': id}
        }
      }
      var list_ward = DataServices.getList('ward');
      var resdata_ward = await this.$apollo.mutate({
        mutation: list_ward,
        variables: variables
      });
      var ward = resdata_ward.data['ward'];
      this.ward = ward;

    },

    async SearchCountry(search, loading){

      var list_country =  dataServices.getList("country")
      var resdata_country = await this.$apollo.mutate({
        mutation: list_country,
      });
      this.list_country_all = resdata_country.data['country'];
      console.log("cdjsdjdsds",loading);
      if(search !== '' && search !== null){
        var where_key = {
          // plain_name:{_ilike: "%"+this.$commonFuction.SkipVN(search).trim()+"%"},
          deleted : {_eq : false},
          plain_name:{_ilike: "%"+this.$commonFuction.SkipVN(search).trim()+"%"},
        };
        let {data} = await this.$apollo.query({
          query: DataServices.getList('country',{"fields" : `id name plain_name`}),
          variables: {
            where_key: where_key,
            orderBy:[{created_at : 'desc'}]
          },
          fetchPolicy: "network-only"
        })
        var list = data[Object.keys(data)];
        var list_unit = [];
        // console.log("list===",list);
        for (let i = 0; i < list.length; i++) {
          const e = list[i];
          list_unit.push({id : e.id, name :e.name});
        }
        this.country = list_unit;
      }else{
        // cosnole.log("dasdasd")
        this.country = this.list_country_all
      }
      return this.country;
    },


    async SearchState(search, loading){
      var country_id = this.selectedCountry.id

      console.log("country_id",country_id)

      var where = {
        "where_key" :{
          "country_id": {"_eq": country_id}
        }
      }
      var list_state =  dataServices.getList("state")
      var resdata_state = await this.$apollo.mutate({
        mutation: list_state,
        variables:where
      });
      this.list_state_all = resdata_state.data['state'];
      console.log("cdjsdjdsds111",this.list_state_all);
      console.log("cdjsdjdsds",loading);
      if(search !== '' && search !== null){
        var where_key = {
          // plain_name:{_ilike: "%"+this.$commonFuction.SkipVN(search).trim()+"%"},
          deleted : {_eq : false},
          plain_name:{_ilike: "%"+this.$commonFuction.SkipVN(search).trim()+"%"},
        };
        let {data} = await this.$apollo.query({
          query: DataServices.getList('state',{"fields" : `id name plain_name fullname`}),
          variables: {
            where_key: where_key,
            orderBy:[{created_at : 'desc'}]
          },
          fetchPolicy: "network-only"
        })
        var list = data[Object.keys(data)];
        var list_unit = [];
        // console.log("list===",list);
        for (let i = 0; i < list.length; i++) {
          const e = list[i];
          list_unit.push({id : e.id, name :e.name});
        }
        this.state = list_unit;
      }else{

        this.state = this.list_state_all
        console.log("dasdasd",this.state)
      }
      return this.state;
    },


    async SearchDistrict(search, loading){
      var state_id  =  this.selectedState.id
      console.log("state_id",state_id)
      var where = {
        "where_key":{
          "state_id":{"_eq":state_id}
        }
      }
      var list_district = DataServices.getList("district");
      var resdata_district = await this.$apollo.mutate({
        mutation: list_district,
        variables:where
      });
      this.list_district_all = resdata_district.data['district'];
      console.log("twettwe")
      console.log("cdjsdjdsds",loading);
      if(search !== '' && search !== null){
        var where_key = {
          // plain_name:{_ilike: "%"+this.$commonFuction.SkipVN(search).trim()+"%"},
          deleted : {_eq : false},
          plain_name:{_ilike: "%"+this.$commonFuction.SkipVN(search).trim()+"%"},
        };
        let {data} = await this.$apollo.query({
          query: DataServices.getList('district',{"fields" : `id name plain_name fullname`}),
          variables: {
            where_key: where_key,
            orderBy:[{created_at : 'desc'}]
          },
          fetchPolicy: "network-only"
        })
        var list = data[Object.keys(data)];
        var list_unit = [];
        // console.log("list===",list);
        for (let i = 0; i < list.length; i++) {
          const e = list[i];
          list_unit.push({id : e.id, name :e.name});
        }
        this.district = list_unit;
      }
      else{
        // cosnole.log("dasdasd")
        this.district = this.list_district_all
        console.log("quanhuyen",this.district)
      }
      return this.district;
    },

    async SearchWard(search, loading){
      var district_id  =  this.selectedDistrict.id
      console.log("district_id",district_id)
      var where = {
        "where_key":{
          "district_id":{"_eq":district_id}
        }
      }
      var list_ward = DataServices.getList("ward");
      var resdata_ward = await this.$apollo.mutate({
        mutation: list_ward,
        variables:where
      });
      this.list_district_all = resdata_ward.data['ward'];
      console.log("twettwe")
      console.log("cdjsdjdsds",loading);
      if(search !== '' && search !== null){
        var where_key = {
          // plain_name:{_ilike: "%"+this.$commonFuction.SkipVN(search).trim()+"%"},
          deleted : {_eq : false},
          plain_name:{_ilike: "%"+this.$commonFuction.SkipVN(search).trim()+"%"},
        };
        let {data} = await this.$apollo.query({
          query: DataServices.getList('ward',{"fields" : `id name plain_name fullname`}),
          variables: {
            where_key: where_key,
            orderBy:[{created_at : 'desc'}]
          },
          fetchPolicy: "network-only"
        })
        var list = data[Object.keys(data)];
        var list_unit = [];
        // console.log("list===",list);
        for (let i = 0; i < list.length; i++) {
          const e = list[i];
          list_unit.push({id : e.id, name :e.name});
        }
        this.ward = list_unit;
      }
      else{
        // cosnole.log("dasdasd")
        this.ward = this.list_ward_all
        console.log("quanhuyen",this.ward)
      }
      return this.ward;
    },

    backToList() {
      this.$emit('back');
    },
    async validateData() {
      this.validationErrors = {};

      let name_add = document.getElementById("name");
      if (this.warehouses.name == undefined || this.warehouses.name == null || this.warehouses.name == ''){
        name_add.setCustomValidity("Tên không được để trống!");
      }else {
        name_add.setCustomValidity("");
      }

      let code_add = document.getElementById("code");
      if (this.warehouses.code == undefined || this.warehouses.code == null || this.warehouses.code == ''){
        code_add.setCustomValidity("Mã không được để trống!");
      }else {
        code_add.setCustomValidity("");
      }

      let address_add = document.getElementById("address");
      if (this.warehouses.address == undefined || this.warehouses.address == null || this.warehouses.address == ''){
        address_add.setCustomValidity("Địa chỉ không được để trống!");
      }else {
        address_add.setCustomValidity("");
      }

      let country_add = document.getElementById("vali_country");
      if (this.$commonFuction.isEmptyObject(this.selectedCountry)){
        country_add.setCustomValidity("Quốc Gia không được để trống!");
      }else {
        country_add.setCustomValidity("");
      }


      let state_add = document.getElementById("vali_state");
      if (this.$commonFuction.isEmptyObject(this.selectedState)){
        state_add.setCustomValidity("Tỉnh /Thành Phố không được để trống!");
      }else {
        state_add.setCustomValidity("");
      }


      let district_add = document.getElementById("vali_district");
      if (this.$commonFuction.isEmptyObject(this.selectedDistrict)){
        district_add.setCustomValidity("Quận/Huyện không được để trống!");
      }else {
        district_add.setCustomValidity("");
      }


      let type_add = document.getElementById("vali_type");
      if (this.$commonFuction.isEmptyObject(this.selectedType)){
        type_add.setCustomValidity("Loại không được để trống!");
      }else {
        type_add.setCustomValidity("");
      }

      let ward_add = document.getElementById("vali_ward");
      if (this.$commonFuction.isEmptyObject(this.selectedWard)){
        ward_add.setCustomValidity("Phường/Xã không được để trống!");
      }else {
        ward_add.setCustomValidity("");
      }




      var inpObj = document.getElementById("frm_action");
      if (!inpObj.checkValidity()){
        console.log('test=====================');
        inpObj.reportValidity();
        return true;
      }
    },

    async saveData() {


      if (await this.validateData()) {
        this.$commonFuction.is_loading(false);
        return
      }
       console.log("test4")


      var country_id ;
      var state_id ;
      var district_id ;
      var ward_id ;
      var type_id ;



      country_id = (this.selectedCountry.id)
      state_id = (this.selectedState.id)
      district_id = (this.selectedDistrict.id)
      ward_id = (this.selectedWard.id)
      type_id = (this.selectedType)
      console.log("wqkwqwq",type_id , country_id )

        var params = {
          name  :this.warehouses.name,
          code  :this.warehouses.code,
          address  :this.warehouses.address,
          country_id:country_id,
          state_id:state_id,
          district_id:district_id,
          ward_id:ward_id,
          country_name:this.selectedCountry.name,
          state_name:this.selectedState.name,
          district_name:this.selectedDistrict.name,
          ward_name:this.selectedWard.name,
          type_id:type_id,
          active:this.warehouses.active,
          protect:this.warehouses.protect
        }
      console.log('saveData', params)
      console.log("nasnasasas",name)

console.log("sâsaaa");
        console.log('saveData', params)
        this.$emit('save', params)
    },
  },
}
</script>

<style lang="scss">
.p-multiselect {
  width: 450px;
}
.p-multiselect-label:not(.p-placeholder) {
  padding-top: .25rem;
  padding-bottom: .25rem;

}
.category-item-value {
  padding: .25rem .5rem;
  border-radius: 3px;
  display: inline-flex;
  margin-right: .2rem;
  background-color: var(--primary-color);
  color: var(--primary-color-text);
}
.p-multiselect-label-container{
  height: 40px;
}

//style form add-edit
label.p-col-fixed txt-right{
  margin-bottom: 0px!important;
}
div.p-card-field{
  padding: 0px!important;
}
.p-col-fixed {
  padding: 0px !important;
  margin:0px 0px 0px 9px !important;
}
.input_text{
  width: 744px!important;
  height: 48px!important;
}
.text-area{
  width: 744px!important;
  min-height: 48px;
}
.txt-right{
  font-weight: bold;
}

.multiselect{
  width: 450px!important;

}
.input_width{
  width: 744px;
  height: 48px;
}
.p-formgrid .fixed-bottom-toolbar{
  border-top: none!important;
}

.text{
  font-weight: normal;
  margin: 12px 0px 0px 15px!important;
}
.margin{
  margin: 12px 0px 12px 9px!important;
}
.layout-main{
  background: #00000029;
}
.p-button-warning{
  border: 1px solid #C40380 !important;

}
#back{
  color: #C40380 !important;
}
.vs__clear{
  display: none;
}
.border_color{
  border: 1px solid #D0D4D9!important;
}
.p-dropdown-label{
  display: flex!important;
  align-items: center!important;
}






//responsive
@media screen and (max-width: 600px) {
  .input_text_responsive{
    width: 100%!important;
  }
  .text-area{
    width: 100%!important;
  }
  .div-button{
    padding: 8px!important;
  }
}

@media screen and (max-width: 1000px) {
  .input_text_responsive{
    width: 100%!important;
  }
  .text-area{
    width: 100%!important;
  }
  .div-button{
    padding: 8px!important;
  }
  #type{
    text-align: left!important;
  }
}


</style>
